<template>
  <div class="rights">
    <img
      src="https://oss.syounggroup.com/bigfile/soyoung-zg/pc/pc-rights-center.png"
      alt="分销商等级权益"
    />
  </div>
</template>

<script>
export default {
  name: 'rights'
};
</script>

<style lang="scss" scoped>
.rights {
  img {
    display: block;
    width: 100%;
  }
}
</style>
